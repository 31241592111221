import Aura from '@primevue/themes/aura'
// @ts-ignore
import { definePreset } from '@primevue/themes'

export default definePreset(Aura, {
  components: {
    button: {
      colorScheme: {
        light: {
          outlined: {
            primary: {
              borderColor: '#cbd5e1', // Primary border color
              textColor: '{primary.500}', // Match text color with border
              hover: {
                background: '#f1f5f9',
                borderColor: '{primary.600}', // Darker on hover
                textColor: '{primary.600}',
              },
              focus: {
                borderColor: '{primary.700}', // Even darker on focus
                textColor: '{primary.700}',
              },
            },
          },
        },
        dark: {
          outlined: {
            primary: {
              borderColor: '{primary.400}', // Lighter color in dark mode
              textColor: '{primary.400}',
              hover: {
                borderColor: '{primary.300}', // Brighter on hover in dark mode
                textColor: '{primary.300}',
              },
              focus: {
                borderColor: '{primary.200}',
                textColor: '{primary.200}',
              },
            },
          },
        },
      },
    },
  },
  primitive: {
    indigo: {
      50: '#faf5ff',
      100: '#e7e6ea',
      200: '#e9d5ff',
      300: '#d8b4fe',
      400: '#c084fc',
      500: '#0f022a',
      600: '#271b3f',
      700: '#3f3555',
      800: '#574e6a',
      900: '#6f677f',
      950: '#878195',
    },
  },
  semantic: {
    primary: {
      50: '{indigo.50}',
      100: '{indigo.100}',
      200: '{indigo.200}',
      300: '{indigo.300}',
      400: '{indigo.400}',
      500: '{indigo.500}',
      600: '{indigo.600}',
      700: '{indigo.700}',
      800: '{indigo.800}',
      900: '{indigo.900}',
      950: '{indigo.950}',
    },
    focusRing: {
      color: '{primary.500}',
    },
  },
})
